body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fafafb !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.cardBox {
  width: 100%;
  border: 1px solid #edf2f9;
  padding: 0.5rem;
  border-radius: 0.625rem;
  font-size: 14px;
  background-color: #fff;
  color: #455560;
}

.cardBox .heightAuto {
  overflow: visible;
}

.cardBox .cardHead {
  border-bottom: 1px solid #edf2f9;
  margin-bottom: 16px;
  padding: 15px 0;
}

.cardBoxDashboard .cardHead .rw-widget-picker {
  border-radius: 34px;
  min-height: 36px;
  border-color: #1976d2;
  color: #1976d2;
  margin-bottom: 16px;
}

.cardBox .cardHead .rw-widget-picker {
  border-radius: 34px;
  min-height: 36px;
  border-color: #1976d2;
  color: #1976d2;
}

.cardBox .cardHead.pt0 {
  padding-top: 0;
}

.cardBox .cardTitle {
  font-size: 1rem;
  font-weight: 700;
  color: #1a3353;
  text-align: left;
  margin: 5px 0 20px 5px;
}

.cardBox .cardHead .cardTitle {
  margin: 0;
}

.cardBox .cardTitle.marB15 {
  margin-bottom: 15px;
}

.selectRight {
  position: relative;
}

.selectRight .selectOuter {
  position: absolute;
  top: 10px;
  right: 20px;
}

.MuiTableContainer-root .MuiTableCell-root {
  color: #1a3353;
  font-size: 14px;
}

.MuiTableContainer-root .MuiTableCell-root.MuiTableCell-head {
  font-weight: 700;
}

.tooltipOuter {
  background: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
}

button.MuiButton-root {
  border-radius: 30px;
  text-transform: inherit;
}

.MuiFormControl-root .MuiOutlinedInput-input {
  padding: 10px 15px;
  min-height: 23px;
}

/* .MuiFormControl-root .MuiOutlinedInput-input.MuiOutlinedInput-input{
  padding: 12px 15px
} */

.MuiFormControl-root .MuiInputLabel-root {
  transform: translate(14px, 9px) scale(1);
}

.MuiFormControl-root .MuiInputLabel-root.MuiInputLabel-shrink {
  transform: translate(14px, -6px) scale(0.75);
}

.MuiFormHelperText-root {
  line-height: normal !important;
}

.MuiFormHelperText-root.Mui-error,
.MuiFormLabel-root.Mui-error,
.datePickerOuter[data-error] .MuiFormHelperText-root,
.datePickerOuter[data-error] .MuiFormLabel-root {
  color: #d32f2f !important;
}

.datePickerOuter[data-error] fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #d32f2f !important;
}

.datePickerOuter[data-error] .MuiFormHelperText-root {
  margin-left: 15px;
}

.MuiPickersToolbar-toolbar {
  height: 50px !important;
  background: #1976d2 !important;
}

.MuiPickersToolbar-toolbar .MuiTypography-h3 {
  font-size: 20px !important;
  font-weight: 700;
}

.MuiPickersBasePicker-pickerView {
  min-width: 190px !important;
}

.MuiPickersBasePicker-pickerView .MuiTypography-subtitle1 {
  font-size: 14px;
  height: 30px;
}

.MuiPickersBasePicker-pickerView .MuiTypography-colorPrimary {
  color: #1976d2 !important;
  font-size: inherit;
  height: 30px;
  font-weight: 700;
}
